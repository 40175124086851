export const de = {
  // General
  'general.footer.info.initial': 'gesetzl. Erstinformation',
  'general.footer.info.imprint': 'Impressum',
  'general.footer.info.privacy': 'Datenschutz',
  'general.page.caption.phone': 'Telefon',
  'general.page.caption.email': 'Email',

  // Confirmation page
  'page.confirmation.title': 'Aktualisieren Ihrer Kundendaten',
  'page.confirmation.subtitle': 'Ihre Datensicherheit ist uns wichtig!',
  'page.confirmation.description': 'Entweder haben Sie uns um eine Änderung Ihrer Daten gebeten oder wir haben einen möglichen Rechtschreibfehler entdeckt. Bitte überprüfen Sie die Änderung.',
  'page.confirmation.change.suggested': 'Die vorgeschlagene Änderung lautet:',
  'page.confirmation.change.current': 'Ihre jetzigen Daten lauten:',
  'page.confirmation.button.confirm': 'Ja, bitte führen Sie diese Änderungen aus',
  'page.confirmation.button.decline': 'Nein, ich möchte dass meine Daten so bleiben',
  'page.confirmation.change.finished': 'Ihre Daten wurden aktualisiert!',
  'page.confirmation.change.actual.data': 'Ihre aktuellen Daten lautet:',
  'page.confirmation.change.left.data': 'Ihre Daten bleiben bei:',
  'page.confirmation.change.description': 'Sie können sich in Ihrem Kundenkonto jederzeit einloggen und Ihre aktuellen Daten einsehen. Klicken Sie {accountLink}, um sich einzuloggen und Ihre Daten direkt zu verwalten.',
  'page.confirmation.change.help': 'Wir sind auch telefonisch für Sie da! Sie erreichen uns von Montag bis Freitag, jeweils von 09:00 bis 18:00 Uhr unter 030-8145862-90.',
  'page.confirmation.change.team': 'Ihr petolo Team',
  'page.confirmation.change.declined.title': 'Alles Klar! Ihre Daten werden nicht geändert.',
  'page.confirmation.change.declined.description': 'Sie können sich in Ihrem Kundenkonto jederzeit einloggen und Ihre aktuellen Daten einsehen. Klicken Sie {accountLink}, um sich einzuloggen und Ihr Daten direkt zu verwalten.',

  // Error page
  'page.error.title': 'Bestätigung der Änderung Ihrer Daten',
  'page.error.subtitle': 'Der Link ist leider abgelaufen.',
  'page.error.subtitle.explanation': 'Bitte wenden Sie sich an petolo Kundenservice, um einen neuen Link zu erhalten.',
  'page.error.customer.service': 'petolo Kundenservice',
  'page.error.customer.service.description': 'Sie erreichen uns von Montag bis Freitag, jeweils von 09:00 bis 18:00 Uhr.',
}
